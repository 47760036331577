<template>
  <v-container fluid>
    <v-row class="d-flex justify-end mb-2" no-gutters>
      <v-col class="d-flex justify-end align-center" md="3" cols="12">
        <v-text-field
          dense
          hide-details
          outlined
          v-model="searchText"
          class="display-2 caption"
          :placeholder="$_strings.parameter.PLACEHOLDER_PARAMETER_NAME"
          ref="search"
          @keyup.enter="search"
        >
          <template v-slot:prepend-inner>
            <v-icon @click="search">
              mdi-magnify
            </v-icon>
          </template>
        </v-text-field>
      </v-col>
    </v-row>
    <v-data-table
      :loading="isLoading"
      :headers="displayedHeader"
      :items="itemsTable"
      class="elevation-1"
      item-key="id"
      :server-items-length="parameterListTotalEntry"
      :options.sync="pagination"
      :footer-props="{showCurrentPage: true, showFirstLastPage: true, 'items-per-page-options': $_item_per_page}"
    >
      <template v-slot:[`item.actions`]="{ item }">
        <v-btn
          @click="onButtonClick(item)"
          color="primary"
          small
        >
          {{userAccess.canUpdate ? $_strings.common.EDIT : $_strings.common.DETAIL}}
        </v-btn>
      </template>
      <template v-slot:[`item.paramGroupName`]=items>
        <span>{{ items.item.parameterGroupName }}</span>
      </template>
      <template v-slot:[`item.paramGroupDesc`]=items>
        <span>{{ items.item.parameterGroupDesc }}</span>
      </template>
      <template v-slot:[`footer.page-text`]="props">
        <span>
          {{$_strings.parameter.DATABASE_NAME}}
          <span v-if="itemsTable.length">
            {{props.pageStart}}-{{props.pageStop}} of {{props.itemsLength}}
          </span>
        </span>
      </template>
    </v-data-table>
  </v-container>
</template>
<script>
import {
  skipEmptyStringObject,
  handleSortBy,
  handlerPagination,
  defaultPagination,
} from '@/helper/commonHelpers';

export default {
  name: 'parameter-masterdata-page',
  data() {
    return {
      isLoading: false,
      searchText: this.$route.query.search || '',
      parameterListPage: 1,
      parameterListSize: 10,
      parameterListTotalEntry: 0,
      headers: [
        {
          text: this.$_strings.parameter.headers.PARAMETER_NAME,
          value: 'paramGroupName',
          width: 300,
        },
        { text: this.$_strings.parameter.headers.DESCRIPTION, value: 'paramGroupDesc' },
        { value: 'actions', sortable: false },
      ],
      pagination: this.defaultPagination(),
      itemsTable: [],
    };
  },
  watch: {
    pagination: {
      handler(newVal) {
        this.handlerPagination(this, newVal);
        if (!this.isLoading) this.fetchData();
      },
      deep: true,
    },
  },
  computed: {
    displayedHeader() {
      return this.headers.map((i) => ({
        ...i,
        class: 'white--text primary',
      }));
    },
  },
  methods: {
    skipEmptyStringObject,
    handleSortBy,
    handlerPagination,
    defaultPagination,
    search() {
      this.$router.replace({
        query: {
          ...this.$route.query,
          search: this.searchText,
        },
      });
      if (this.pagination.page > 1) {
        this.pagination.page = 1;
        return;
      }
      this.fetchData();
    },
    fetchData() {
      const {
        page, itemsPerPage, sortBy, sortDesc,
      } = this.pagination;
      const filters = {
        page: page - 1,
        size: itemsPerPage,
        paramGroupName: this.searchText ? `qLike(${this.searchText})` : '',
        sort: this.handleSortBy({ sortBy, sortDesc }),
      };
      this.isLoading = true;
      this.$_services.parameter.fetchParameterListGroup(this.skipEmptyStringObject(filters))
        .then((result) => {
          this.itemsTable = result.data.contents;
          this.parameterListTotalEntry = result.data.totalData;
          this.parameterListPage = result.data.page;
        })
        .finally((e) => {
          this.isLoading = false;
        });
    },
    onButtonClick(item) {
      this.$router.push(`list-update/${item.id}/${item.parameterGroupName}`);
    },
  },
};
</script>
